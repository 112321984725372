/* VARIABLE FONTS
one font file for every weight
another one for italic
*/

@font-face {
    font-family: 'Open Sans Variable';
    font-weight: 300 800; /* range from 300 to 800 */
    font-display: swap;
    src: url("../fonts/woff2/OpenSans-variable.woff2") format('woff2');
}
@font-face {
    font-family: 'Open Sans Variable';
    font-style: italic;
    font-weight: 300 800; /* range from 300 to 800 */
    font-display: swap;
    src: url("../fonts/woff2/OpenSans-italic-variable.woff2") format('woff2');
}

/*FALLBACK FONTS*/
/*Normal*/
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-stretch: normal;
    font-display: swap;
    src: url("../fonts/woff2/OpenSans-Light.woff2") format('woff2'),
         url("../fonts/woff/OpenSans-Light.woff") format('woff'),
         url("../fonts/ttf/OpenSans-Light.ttf") format('truetype');
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-stretch: normal;
    font-display: swap;
    src: url("../fonts/woff2/OpenSans-Regular.woff2") format('woff2'),
         url("../fonts/woff/OpenSans-Regular.woff") format('woff'),
         url("../fonts/ttf/OpenSans-Regular.ttf") format('truetype');
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-stretch: normal;
    font-display: swap;
    src: url("../fonts/woff2/OpenSans-Bold.woff2") format('woff2'),
         url("../fonts/woff/OpenSans-Bold.woff") format('woff'),
         url("../fonts/ttf/OpenSans-Bold.ttf") format('truetype');
}

/*Italic*/
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 300;
    font-stretch: normal;
    font-display: swap;
    src: url("../fonts/woff2/OpenSans-LightItalic.woff2") format('woff2'),
         url("../fonts/woff/OpenSans-LightItalic.woff") format('woff'),
         url("../fonts/ttf/OpenSans-LightItalic.ttf") format('truetype');
}

@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    font-stretch: normal;
    font-display: swap;
    src: url("../fonts/woff2/OpenSans-Italic.woff2") format('woff2'),
         url("../fonts/woff/OpenSans-Italic.woff") format('woff'),
         url("../fonts/ttf/OpenSans-Italic.ttf") format('truetype');
}

@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 700;
    font-stretch: normal;
    font-display: swap;
    src: url("../fonts/woff2/OpenSans-BoldItalic.woff2") format('woff2'),
         url("../fonts/woff/OpenSans-BoldItalic.woff") format('woff'),
         url("../fonts/ttf/OpenSans-BoldItalic.ttf") format('truetype');
}

/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/woff2/Roboto-300.woff2') format('woff2'),
       url('../fonts/woff/Roboto-300.woff') format('woff'),
       url('../fonts/ttf/Roboto-300.ttf') format('truetype');
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/woff2/Roboto-regular.woff2') format('woff2'),
       url('../fonts/woff/Roboto-regular.woff') format('woff'),
       url('../fonts/ttf/Roboto-regular.ttf') format('truetype');
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/woff2/Roboto-500.woff2') format('woff2'),
       url('../fonts/woff/Roboto-500.woff') format('woff'),
       url('../fonts/ttf/Roboto-500.ttf') format('truetype');
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/woff2/Roboto-700.woff2') format('woff2'),
       url('../fonts/woff/Roboto-700.woff') format('woff'),
       url('../fonts/ttf/Roboto-700.ttf') format('truetype');
}
